import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import UserService from "../services/user.service.js";


// Hook: Get User
export const useGetUser = () => {
  return useQuery({
    queryKey: ["user"],
    queryFn: UserService.getUser,
    refetchInterval: 10000,
    onError: (error) => {
      console.error("Failed to fetch user data:", error);
    },
  });
};

// Hook: Get Records
export const useGetRecords = (scrapingId, page = 1, pageSize = 100) => {
  return useQuery({
    queryKey: ["records", scrapingId, page, pageSize],
    queryFn: async () => {
      const response = await UserService.getRecords(scrapingId, page, pageSize);
      return response;
    },
    enabled: !!scrapingId,
    onError: (error) => {
      console.error("Failed to fetch records:", error);
    },
  });
};

// Hook: Start Scraping
export const useStartScraping = (scrapingId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: scrapingId => UserService.startScraping(scrapingId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["scrapings", scrapingId],
      });
    },
    onError: (error) => {
      console.error("Failed to start scraping:", error);
    },
  });
};

// Hook: Stop Scraping
export const useStopScraping = (scrapingId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: scrapingId => UserService.stopScraping(scrapingId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["scrapings"],
      });
    },
    onError: (error) => {
      console.error("Failed to stop scraping:", error);
    },
  });
};

// Hook: Get Scrapings
export const useGetScrapings = () => {
  return useQuery({
    queryKey: ["scrapings"],
    queryFn: UserService.getScrapings,
    staleTime: 0,
    onError: (error) => {
      console.error("Failed to fetch scrapings:", error);
    },
  });
};

export const useGetScraping = (scrapingId) => {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: [scrapingId],
    queryFn: () => UserService.getScraping(scrapingId),
    enabled: !!scrapingId,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [scrapingId],
      });
    },
    onError: (error) => {
      console.error("Failed to fetch scraping:", error);
    },
  });
}

// Hook: Download Records
export const useDownloadRecords = () => {
  return useMutation({
    mutationFn: (scrapingId, fields, format, hideDuplicatePhones) => UserService.downloadRecords(scrapingId, fields, format, hideDuplicatePhones),
    onError: (error) => {
      console.error("Failed to download records:", error);
    },
  });
};

// Hook: Create Scraping
export const useCreateScraping = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({url, filters}) => UserService.createScraping(url, filters),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["scrapings"],
      });
    },
    onError: (error) => {
      console.error("Failed to create scraping:", error);
    },
  });
};

// Hook: Get Records Count
export const useGetRecordsCount = (scrapingId) => {
  return useQuery({
    queryKey: ["recordsCount", scrapingId],
    queryFn: () => UserService.getRecordsCount(scrapingId),
    enabled: !!scrapingId, // Zapytanie jest aktywne tylko, jeśli scrapingId istnieje
    onError: (error) => {
      console.error("Failed to get records count:", error);
    },
  });
};



export const useDeleteScraping = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (scrapingId) => UserService.deleteScraping(scrapingId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["scrapings"],
      });
    },
    onError: (error) => {
      console.error("Failed to delete scraping:", error.response?.data?.message || error.message);
    },
  });
};