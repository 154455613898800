import { Table } from "antd";
import { useMemo } from "react";

export default function TableComponent({
  records,
  loading,
  activeFields,
  hideDuplicatePhones,
  totalRecords,
  page,
  pageSize,
  onPageChange,
}) {
  // Мемоизация для избежания лишних пересчетов
  const processedRecords = useMemo(() => {
    let filteredRecords = [...records];

    // Фильтрация дубликатов по номеру телефона (если включено)
    if (hideDuplicatePhones) {
      const phoneCounts = filteredRecords.reduce((acc, record) => {
        acc[record.phone] = (acc[record.phone] || 0) + 1;
        return acc;
      }, {});
      filteredRecords = filteredRecords.filter(record => phoneCounts[record.phone] === 1);
    }

    // Обработка boolean значений ("Yes" / "No")
    return filteredRecords.map(record => 
      Object.fromEntries(
        Object.entries(record).map(([key, value]) => [
          key, 
          typeof value === "boolean" ? (value ? "Yes" : "No") : value
        ])
      )
    );
  }, [records, hideDuplicatePhones]); // Обновляем только при изменении данных

  // Колонки формируются на основе activeFields
  const columns = useMemo(() => activeFields.map((key) => ({
    title: key.charAt(0).toUpperCase() + key.slice(1),
    dataIndex: key,
    key,
    width: 120,
    ellipsis: true,
    render: (text, record) => key === "images" ? JSON.stringify(text) : text,
  })), [activeFields]);

  return (
    <Table
      dataSource={processedRecords}
      columns={columns}
      size="small"
      pagination={{
        current: page,
        pageSize,
        total: totalRecords,
        onChange: onPageChange, // Передаём обработчик смены страницы
        showSizeChanger: false, // Оставляем фиксированный pageSize
      }}
      scroll={{ x: 2000 }}
      loading={loading}
      rowKey={(record) => record._id || record.id} // Уникальный ключ для строк
    />
  );
}